import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/alarmConfig/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/alarmConfig/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/alarmConfig/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/alarmConfig/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/alarmConfig/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/alarmConfig/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/alarmConfig/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/base/alarmConfig/exportXls", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,exportXls
}