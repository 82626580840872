
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    width="800px;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="考勤单位" prop="companyId">
          <!-- <el-select v-model="formModel.companyId" filterable placeholder="请选择" style="width:100% ">
            <el-option
              v-for="company in companyResult"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            ></el-option>
          </el-select>-->
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            width="300px"
            size="mediumn"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="是否包含下级单位" prop="subordinate">
          <el-radio v-model="formModel.subordinate" :label="true">是</el-radio>
          <el-radio v-model="formModel.subordinate" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item label="周工作日" prop="weekdays">
          <el-select
            v-model="formModel.weekdays"
            filterable
            multiple
            placeholder="请选择"
            style="width:100% "
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- <el-checkbox-group v-model="formModel.weekdays" size="small">
              <el-checkbox-button v-for="item in options" :label="item.value" :key="item.value">{{item.label}}</el-checkbox-button>
            </el-checkbox-group> -->
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-time-picker
            v-model="formModel.startTime"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm"
            :picker-options="{
                  selectableRange:`00:00:00 -${formModel.endTime ? formModel.endTime+':00' : '23:59:00'}`
                }"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="考勤时间" prop="attendanceTime">
          <el-time-picker
            v-model="formModel.attendanceTime"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-time-picker
            v-model="formModel.endTime"
            class="date-box"
            format="HH:mm"
            value-format="HH:mm"
            :picker-options="{
                  selectableRange: `${formModel.startTime ? formModel.startTime+':00' : '00:00:00'}-23:59:00`
                }"
          ></el-time-picker>
        </el-form-item>
        <el-form-item label="考勤类型" prop="attendanceType">
          <el-radio v-model="formModel.attendanceType" :label="1">内勤</el-radio>
          <el-radio v-model="formModel.attendanceType" :label="2">外勤</el-radio>
        </el-form-item>
        <el-form-item label="考勤类别" prop="classifier">
          <el-radio v-model="formModel.classifier" :label="1">上班时间</el-radio>
          <el-radio v-model="formModel.classifier" :label="2">下班时间</el-radio>
        </el-form-item>
        <el-form-item label="需要提醒" prop="needMeasureTemperature">
          <el-radio v-model="formModel.needMeasureTemperature" :label="true">是</el-radio>
          <el-radio v-model="formModel.needMeasureTemperature" :label="false">否</el-radio>
        </el-form-item>
        <!-- <el-form-item label="纳入统计" prop="realTimeStat">
          <el-radio v-model="formModel.realTimeStat" :label="1">是</el-radio>
          <el-radio v-model="formModel.realTimeStat" :label="0">否</el-radio>
        </el-form-item> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import alarmConfigApi from "@/api/base/alarmConfig";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import { quillEditor } from "vue-quill-editor";

export default {
  props: ["businessKey", "title", "companyResult"],
  data() {
    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        weekdays: [{ required: true, message: "不能为空", trigger: "blur" }],
        startTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        attendanceTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        endTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        classifier: [{ required: true, message: "不能为空", trigger: "blur" }],
        attendanceType: [{ required: true, message: "不能为空", trigger: "blur" }],
        needMeasureTemperature: [{ required: true, message: "不能为空", trigger: "blur" }]
        // realTimeStat: [{ required: true, message: "不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      rangeTime: "",
      options: [
        {
          value: "1",
          label: "周一"
        },
        {
          value: "2",
          label: "周二"
        },
        {
          value: "3",
          label: "周三"
        },
        {
          value: "4",
          label: "周四"
        },
        {
          value: "5",
          label: "周五"
        },
        {
          value: "6",
          label: "周六"
        },
        {
          value: "7",
          label: "星期天"
        }
      ],

      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    this.loadTree();
  },
  methods: {
    getSelectedValue(value) {
      this.formModel.companyId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;
            self.submitting = true;

            var requestModel = JSON.parse(JSON.stringify(self.formModel));

            if (requestModel.weekdays != null) {
              var weekdays = requestModel.weekdays.join(",");
              requestModel.weekdays = weekdays;
            }

            if (requestModel.companyId == null) {
              requestModel.companyId = "";
            }

            if (id == null || id.length == 0) {
              return alarmConfigApi.add(requestModel);
            } else {
              return alarmConfigApi.update(requestModel);
            }
          })().then(function(response) {
            self.submitting = false;

            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    (function() {
      if (self.businessKey.length == 0) {
        return alarmConfigApi.create();
      } else {
        return alarmConfigApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if (self.businessKey.length == 0) {
            self.formModel.status = false;
          }

          var weekdays = self.formModel.weekdays;
          if (weekdays != null) {
            self.formModel.weekdays = weekdays.split(",");
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },

  components: {
    "el-select-tree": SelectTree
  }
};
</script>